<template>
  <v-container fluid>
    <v-card tile>
      <v-toolbar>
        <v-btn icon :to="`/admin/${path}`" exact>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>New device</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form @submit.prevent="saveItem">
          <v-text-field v-model="item.name" label="Name"></v-text-field>
          <v-text-field v-model="item.macAddress" label="MAC Address"></v-text-field>
          <v-text-field v-model="item.type" label="Type"></v-text-field>
          <v-autocomplete
            v-model="item.user"
            :items="users"
            :item-text="(user) => `UID: ${user.id} - ${user.name} ${user.surname}`"
            :item-value="(user) => user.id"
            return-object
            solo
            label="USER"
          >
          </v-autocomplete>
          <v-btn type="submit" color="success">Save</v-btn>
          <v-btn text @click="$router.push(`/admin/${path}`)">Cancel</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      users: [],
      item: Object(),
      path: 'devices'
    }
  },
  methods: {
    async saveItem () {
      await this.$store.dispatch('rest/createItem', {
        path: this.path,
        item: this.item
      })
      await this.$router.push(`/admin/${this.path}`)
    }
  },
  async mounted () {
    document.title = 'New device | Masarka Student Club'
    this.users = await this.$store.dispatch('rest/fetchAllItems', {
      path: 'users'
    })
  }
}
</script>
